import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import QrReader from "react-qr-scanner"
import { useLocation } from "@reach/router"
import { isIMEI } from "validator"
import PopOver from "app/PopOver"
import tw from "twin.macro"
import { InstallationContext } from "./context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { DevicesContext } from "app/Devices/context"
import { DataList } from "app/DataList"
import { Search } from "app/Search"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import {
  Beacon,
  DeviceStockStatusEnum,
  DeviceTypeCategoryEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import {
  Icon,
  Button,
  Responsive,
  ButtonGroup,
  Card,
  Tag,
} from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { SearchActions, SearchTypes } from "app/Search/actions"
import styled from "styled-components"
import { InstallationActions, InstallationTypes } from "./actions"
import { useAccounts } from "app/Account/hooks"
import { useUser } from "app/User/hooks"
import {
  generateDeviceCalibrationStep,
  generateDeviceHealthStep,
  generateDeviceInformationStep,
  generateInstallationVerificationStep,
} from "./Flow/helper"
import {
  createVehicle,
  getVehicleCalibration,
  getVehicleFeatures,
} from "services/vehicles"
import { useFirestoreDevices } from "services/firestore/devices"
import { useInstallation } from "./hooks"
import { InstallationScreenEnum, InstallationStep } from "./types"
import format from "date-fns/format"
import { AccountsContext } from "app/Account/context"
import { FeatureComponent } from "app/FeatureComponent"
import { ApiAccountTypeEnum } from "app/Account/types"
import { queryResolver } from "utils/queryResolver"
import uniqBy from "lodash-es/uniqBy"

const StyledQrPopover = styled.div`
  ${tw`bg-white w-full h-full flex flex-col items-center justify-center`}

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
    ${tw`flex flex-col items-center justify-center`}

    svg {
      width: 80vw;
      height: 80vw;
      margin-top: 4.5rem;
      margin-bottom: 4rem;
      fill: white;
      opacity: 0.6;
    }
  }

  .qr-wrapper {
    width: 90vw;
    height: 90vw;
    position: absolute;
    z-index: 50;

    video {
      margin-top: -3rem;
      width: 90vw;
      height: 90vw;
      object-fit: cover;
    }
  }
`

export const Installation: React.FC = ({ ...props }) => {
  const {
    state: { account, vehicles, accountDevices },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { account: installerAccount },
  } = useContext(AccountsContext)
  const {
    state: { results, query, suggestions },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  useLockBodyScroll(true)
  const {
    saveFirebaseDevice,
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const { assignedAccounts } = useAccounts()
  const { assertDeviceTypeCategory, getAccountApiVehicles } = useInstallation()

  const { currentUserIsExternalFitter } = useUser()
  const [qrScanToggled, setQrScanToggled] = useState(false)
  const [showStockDevices, setShowStockDevices] = useState(false)

  const location = useLocation()

  const urlParams = useMemo(() => {
    if (location.search) {
      return queryResolver(location.search)
    }
  }, [location])

  const installerDevices = useMemo(() => {
    if (
      installerAccount &&
      installerAccount.description === ApiAccountTypeEnum.Installer &&
      devices
    ) {
      return devices
        .filter((dev) => +dev.accountID === +installerAccount.id)
        .map((x) => ({
          ...x,
          name: x.imei,
        }))
    }
    return []
  }, [installerAccount, devices])

  const datasetSearch = useMemo(() => {
    const searchDevices = [...installerDevices, ...accountDevices]
    if (searchDevices.length === 0) return null
    return freetextSearch(searchDevices, {
      threshold: 0.05,
      location: 4,
      distance: 20,
      ignoreLocation: true,
      keys: ["imei", "name", "deviceTypeName"],
      includeScore: true,
    })
  }, [accountDevices, installerDevices])

  const onScanQRHandler = useCallback((result) => {
    if (result && isIMEI(result.text)) {
      setQrScanToggled(false)
      searchDispatch(
        SearchActions(SearchTypes.SetQuery, { query: result.text })
      )
    }
  }, [])

  const alternateAccount = useMemo(() => {
    if (results.length === 0 && query.length > 0) {
      const findDevice = devices.find((dev) => dev.imei === query)
      if (findDevice) {
        return assignedAccounts.find(
          (acc) => acc.id === findDevice.accountID
        ) ?? installerAccount.id === findDevice.accountID
          ? installerAccount
          : null
      }
      return null
    }
    return null
  }, [devices, results, query, assignedAccounts])

  const switchAccount = async (account) => {
    dispatch(InstallationActions(InstallationTypes.SetAccount, { account }))
  }

  const resolveInstallationSteps = async (device) => {
    if (device?.imei) {
      const savedSteps = (await getInstallationStepsByImei(
        device.imei
      )) as InstallationStep[]

      const deviceInfoStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      const installationVerificationStep = savedSteps.find(
        (step) =>
          step.stepID === InstallationScreenEnum.InstallationVerification
      )
      const deviceCalibrationStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
      )
      const deviceHealthStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceHealthValidation
      )

      // Needs some rework. Values fetched from the api, is being overwritten,
      // by data from the step itself.
      // Pass the step data to the generator instead, and use preexisting values from the API,
      // where applicable.
      const steps = [
        deviceInfoStep
          ? {
              ...deviceInfoStep,
              data: {
                ...generateDeviceInformationStep(device).data,
                ...deviceInfoStep.data,
              },
            }
          : generateDeviceInformationStep(device),
        installationVerificationStep
          ? {
              ...installationVerificationStep,
              data: {
                ...generateInstallationVerificationStep(
                  device,
                  installerAccount
                ).data,
                ...installationVerificationStep.data,
              },
            }
          : generateInstallationVerificationStep(device, installerAccount),
        deviceCalibrationStep
          ? {
              ...deviceCalibrationStep,
              data: {
                ...generateDeviceCalibrationStep(device).data,
              },
            }
          : generateDeviceCalibrationStep(device),
        deviceHealthStep
          ? {
              ...deviceHealthStep,
              data: {
                ...generateDeviceHealthStep(device).data,
                ...deviceHealthStep.data,
              },
            }
          : generateDeviceHealthStep(device),
      ]

      if (savedSteps.length === 0) {
        // Fresh item, load up defaults into firestore
        await Promise.all(
          [
            saveInstallationStepByImei(
              device?.imei,
              InstallationScreenEnum.DeviceInformation,
              steps.find(
                (s) => s.stepID === InstallationScreenEnum.DeviceInformation
              )
            ),
            ![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(assertDeviceTypeCategory(device))
              ? saveInstallationStepByImei(
                  device?.imei,
                  InstallationScreenEnum.DeviceCalibration,
                  steps.find(
                    (s) => s.stepID === InstallationScreenEnum.DeviceCalibration
                  )
                )
              : null,
            saveInstallationStepByImei(
              device?.imei,
              InstallationScreenEnum.InstallationVerification,
              steps.find(
                (s) =>
                  s.stepID === InstallationScreenEnum.InstallationVerification
              )
            ),
            ![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(assertDeviceTypeCategory(device))
              ? saveInstallationStepByImei(
                  device?.imei,
                  InstallationScreenEnum.DeviceHealthValidation,
                  steps.find(
                    (s) =>
                      s.stepID === InstallationScreenEnum.DeviceHealthValidation
                  )
                )
              : null,
          ].filter(Boolean)
        )
      }

      dispatch(
        InstallationActions(InstallationTypes.SetInstallationSteps, {
          steps,
        })
      )
      return
    }

    dispatch(
      InstallationActions(InstallationTypes.SetInstallationSteps, {
        steps: null,
      })
    )

    return
  }

  const onSelectDeviceHandler = async (item) => {
    if (
      item.stockStatus === DeviceStockStatusEnum.Stock &&
      account?.id &&
      item.unit_id &&
      !item.availableUnit
    ) {
      await saveFirebaseDevice(
        {
          installationStartedOnAccountID: account.id,
          imei: item.imei.toString(),
          stockStatus: DeviceStockStatusEnum.Awaiting,
          statusUpdated: +new Date(),
          unit_id: item.unit_id,
        },
        item.imei.toString()
      )
    }

    if (item.imei) {
      const accountVehicle =
        vehicles.find((dvc) => +dvc.imei === +item.imei) ?? {}
      const apiCalibReq = getVehicleCalibration(accountVehicle?.id ?? item.id)
      const apiFeatureReq = getVehicleFeatures(accountVehicle?.id ?? item.id)
      const [apiDeviceCalibration, apiFeatures] = await Promise.all([
        apiCalibReq,
        apiFeatureReq,
      ])
      const itemWithMeta = {
        ...item,
        ...accountVehicle,
        id: accountVehicle?.id ?? null,
        unit_id: item?.unit_id ?? accountVehicle?.unit_id,
        name: accountVehicle?.name ?? item.imei,
        deviceType: item?.deviceType?.toString(),
        deviceTypeName: item?.deviceTypeName,
      }

      if (
        apiDeviceCalibration.result === "OK" &&
        apiDeviceCalibration.vehicleCalibrationData
      ) {
        itemWithMeta.calibration = apiDeviceCalibration.vehicleCalibrationData
      }

      if (apiFeatures.result === "OK" && apiFeatures.features) {
        itemWithMeta.features = apiFeatures.features
      }

      await resolveInstallationSteps(itemWithMeta)

      await saveFirebaseDevice(
        {
          ...itemWithMeta,
        },
        item.imei.toString()
      )

      dispatch(
        InstallationActions(InstallationTypes.SetDevice, {
          device: itemWithMeta,
        })
      )
      dispatch(
        InstallationActions(
          InstallationTypes.SetScreen,
          InstallationScreenEnum.Tasks
        )
      )
      return
    }

    await resolveInstallationSteps(item)

    dispatch(InstallationActions(InstallationTypes.SetDevice, { device: item }))
    dispatch(
      InstallationActions(
        InstallationTypes.SetScreen,
        InstallationScreenEnum.Tasks
      )
    )
  }

  const onSelectNewAccountHandler = () => {
    dispatch(InstallationActions(InstallationTypes.ClearAccount))
    dispatch(
      InstallationActions(InstallationTypes.SetAccountDevices, {
        accountDevices: [],
      })
    )
  }

  const onCancelHandler = () => {
    setShowStockDevices(false)
    searchDispatch(SearchActions(SearchTypes.ResetSearch))
  }

  const renderAlternateAccount = () => {
    if (alternateAccount && installerAccount) {
      const isOwnAccount = alternateAccount.id === installerAccount.id
      const foundDevice = devices.find((dev) => dev.imei === query)
      return isOwnAccount ? (
        <>
          <Card tw="leading-snug mt-8 text-xl m-0 p-4 py-8 bg-brand-gray-brand space-y-4">
            <span tw="text-lg m-0">Enheden findes på dit eget lager.</span>
            <strong tw="block">
              <span tw="block text-lg">IMEI: {foundDevice?.imei}</span>
              <span tw="block text-lg">
                Enhedstype: {foundDevice?.deviceTypeName}
              </span>
            </strong>
            <Button
              variant="primary"
              size="sm"
              onClick={() => onSelectDeviceHandler(foundDevice)}
            >
              Tildel enheden til {account?.name}
            </Button>
          </Card>
        </>
      ) : (
        <>
          <p tw="text-xl">
            Enheden med IMEI {query} findes på {alternateAccount.name}
          </p>
          <Button
            variant="default"
            size="sm"
            onClick={() => switchAccount(alternateAccount)}
          >
            Skift til {alternateAccount.name}
          </Button>
        </>
      )
    }
    return null
  }

  const [
    installerStockDevices,
    installerAwaitingDevices,
    stockDevices,
    awaitingDevices,
    installedDevices,
    unverifiedDevices,
  ] = useMemo(() => {
    if (datasetSearch && query.length >= 2) {
      const searchResults = datasetSearch
        .search(query)
        .map((x) => x.item) as Partial<(Beacon | Tracker) & StockDevice[]>
      return [
        searchResults.filter((x) => +x.accountID === +installerAccount.id),
        searchResults.filter(
          (x) =>
            x.stockStatus === DeviceStockStatusEnum.Awaiting &&
            +x?.installationStartedOnAccountID === +account.id
        ),
        searchResults.filter(
          (x) =>
            x.stockStatus === DeviceStockStatusEnum.Stock &&
            +x.accountID === +account.id
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Installed
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Legacy
        ),
      ]
    }
    return [
      installerDevices.filter((x) => +x.accountID === +installerAccount.id),
      installerDevices.filter(
        (x) =>
          x.stockStatus === DeviceStockStatusEnum.Awaiting &&
          +x?.installationStartedOnAccountID === +account?.id
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Stock
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Installed
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Legacy
      ),
    ]
  }, [query, accountDevices, installerDevices, datasetSearch, account])

  return (
    <PopOver
      fromBottom
      show={account !== null}
      zindex={2000}
      selector="#___wrapper"
      tw="absolute shadow-none overflow-hidden"
    >
      <div tw="w-full h-full overflow-y-scroll bg-white">
        <header tw="p-8 pb-4 sticky top-0 bg-white z-50">
          <span tw="flex items-baseline justify-between">
            <span tw="block mb-2">Monterer for</span>
            {!currentUserIsExternalFitter && (
              <span
                tw="text-brand-500 flex items-center text-right whitespace-nowrap"
                onClick={onSelectNewAccountHandler}
              >
                <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
                <span tw="text-lg font-normal">Vælg en anden konto</span>
              </span>
            )}
          </span>
          <span tw="text-2xl font-bold">
            <span tw="w-2/3">{account?.name}</span>
          </span>
          {datasetSearch && (
            <div tw="bg-white mt-8 flex items-center">
              <div tw="flex-1">
                <Search dataset={datasetSearch} placeholder="Søg efter enhed" />
              </div>
              {urlParams?.disableQR !== "1" && (
                <Button
                  variant="icon"
                  invert
                  tw="p-0 w-12 h-12 m-0 ml-4 -mt-4"
                  onClick={() => setQrScanToggled(true)}
                >
                  <span tw="flex flex-col items-center">
                    <Icon
                      icon="qrcode"
                      size={IconSizeEnum.AUTO}
                      tw="text-brand-black-base w-12 h-12"
                    />
                    <span tw="text-sm block text-nowrap -mt-2">Scan QR</span>
                  </span>
                </Button>
              )}

              <PopOver
                fromBottom
                show={qrScanToggled}
                zindex={2100}
                tw="overflow-y-hidden"
              >
                <StyledQrPopover>
                  <div className="overlay">
                    <h3 tw="m-0">Scan enhedens QR-kode (beta)</h3>
                    <Icon icon="expand" />

                    <Button
                      variant="cancel"
                      onClick={() => setQrScanToggled(false)}
                    >
                      Annullér
                    </Button>
                    <span tw="block">
                      <strong>Tips: </strong>Hold en finger over S/N QR-koden.
                    </span>
                    <span tw="block">Funktionen virker bedst på iPhone.</span>
                  </div>
                  <div className="qr-wrapper">
                    {qrScanToggled && (
                      <QrReader
                        key="environment"
                        onScan={onScanQRHandler}
                        constraints={{
                          facingMode: "environment",
                          video: {
                            facingMode: "environment",
                          },
                        }}
                        legacyMode={true}
                      />
                    )}
                  </div>
                </StyledQrPopover>
              </PopOver>
            </div>
          )}
        </header>
        <div id="deviceList">
          {!showStockDevices && (
            <>
              {stockDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    <span>På lager</span>
                  </h4>
                  <DataList
                    items={stockDevices}
                    titleKey="name"
                    titleIcon="warehouse-alt"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center text-brand-blue-50`}
                    descriptionKey={["note"]}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKey={"statusUpdated"}
                    tertiaryDescriptionKeyFormatFnc={(x) => {
                      if (!x) return null
                      return `Lagerført ${format(
                        new Date(x),
                        "dd.MM.yyyy 'kl.' HH:mm"
                      )}`
                    }}
                    tertiaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKeySeparator={","}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {(awaitingDevices.length > 0 ||
                installerAwaitingDevices.length > 0) && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    Igangværende monteringer
                  </h4>
                  <DataList
                    items={uniqBy(
                      [...awaitingDevices, ...installerAwaitingDevices],
                      "imei"
                    )}
                    titleKey="name"
                    titleIcon="exclamation-triangle"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center text-brand-yellow-200`}
                    descriptionKey={["note"]}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKey={"statusUpdated"}
                    tertiaryDescriptionKeyFormatFnc={(x) => {
                      if (!x) return null
                      return `Påbegyndt ${format(
                        new Date(x),
                        "dd.MM.yyyy 'kl.' HH:mm"
                      )}`
                    }}
                    tertiaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKeySeparator={","}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {installedDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50 flex items-center">
                    Monteret
                  </h4>
                  <DataList
                    items={installedDevices}
                    titleKey="name"
                    titleIcon="check"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center text-green-600`}
                    descriptionKey={["note"]}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKey={"statusUpdated"}
                    tertiaryDescriptionKeyFormatFnc={(x) => {
                      if (!x) return null
                      return `Monteret ${format(
                        new Date(x),
                        "dd.MM.yyyy 'kl.' HH:mm"
                      )}`
                    }}
                    tertiaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKeySeparator={","}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {unverifiedDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    Ikke verificerede
                  </h4>
                  <DataList
                    items={unverifiedDevices}
                    titleIcon="question-circle"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center`}
                    titleKey="name"
                    descriptionKey={["note"]}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {results.length === 0 &&
              suggestions.length === 0 &&
              query.length > 0 ? (
                <div tw="text-center p-8">
                  <p tw="text-2xl m-0">
                    Enheden findes ikke på {account?.name}
                  </p>
                  {renderAlternateAccount()}
                </div>
              ) : null}
            </>
          )}
        </div>
        <FeatureComponent allowedAccountTypes={[ApiAccountTypeEnum.Installer]}>
          <ButtonGroup
            sticky="bottom"
            position="around"
            tw="px-4 bottom-0 bg-white"
          >
            <Button
              type="button"
              variant="primary"
              onClick={() => setShowStockDevices(true)}
            >
              <span tw="flex items-center">
                <span tw="text-xl">Tilføj ny enhed fra lager</span>
                <Icon icon="plus" tw="w-5 h-5 ml-1" />
              </span>
            </Button>
          </ButtonGroup>
        </FeatureComponent>
        <PopOver
          fromBottom
          show={showStockDevices}
          selector="#deviceList"
          zindex={2100}
          tw="absolute shadow-none overflow-hidden top-52 bottom-0"
        >
          <div tw="w-full h-full overflow-y-scroll bg-white ">
            {installerStockDevices.length > 0 && (
              <>
                <h4 tw="p-8 m-0 sticky top-0 bg-white z-50">
                  <span>Montering i gang</span>
                </h4>
                <DataList
                  items={installerStockDevices.filter(
                    (dev) => dev.stockStatus === DeviceStockStatusEnum.Awaiting
                  )}
                  titleKey="name"
                  titleIcon="exclamation-triangle"
                  titleIconStyling={tw`w-6 h-6 mr-4`}
                  titleStyling={tw`flex items-center text-brand-yellow-200`}
                  descriptionKey={["note"]}
                  secondaryDescriptionKey={[
                    "imei",
                    "deviceTypeName",
                    "installationStartedOnAccountID",
                  ]}
                  secondaryDescriptionStyling={tw`text-lg`}
                  secondaryDescriptionKeyFormatFnc={(x, k) => {
                    if (k === "installationStartedOnAccountID") {
                      return (
                        <Tag
                          tw="bg-brand-yellow-200 text-brand-black-base"
                          show
                        >
                          Påbegyndt på '
                          {
                            assignedAccounts.find((acc) => +acc.id === +x[k])
                              ?.name
                          }
                          '
                        </Tag>
                      )
                    }
                    return x[k]
                  }}
                  tertiaryDescriptionKey={"statusUpdated"}
                  tertiaryDescriptionKeyFormatFnc={(x) => {
                    if (!x) return null
                    return `Påbegyndt ${format(
                      new Date(x),
                      "dd.MM.yyyy 'kl.' HH:mm"
                    )}`
                  }}
                  tertiaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKeySeparator={","}
                  onItemSelect={onSelectDeviceHandler}
                />
                <h4 tw="p-8 m-0 sticky top-0 bg-white z-50">
                  <span>Ledige enheder på lager</span>
                </h4>
                <DataList
                  items={installerStockDevices.filter(
                    (dev) => dev.stockStatus === DeviceStockStatusEnum.Stock
                  )}
                  titleKey="name"
                  titleIcon="warehouse-alt"
                  titleIconStyling={tw`w-6 h-6 mr-4`}
                  titleStyling={tw`flex items-center text-brand-blue-50`}
                  descriptionKey={["note"]}
                  secondaryDescriptionKey={["imei", "deviceTypeName"]}
                  secondaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKey={"statusUpdated"}
                  tertiaryDescriptionKeyFormatFnc={(x) => {
                    if (!x) return null
                    return `Lagerført ${format(
                      new Date(x),
                      "dd.MM.yyyy 'kl.' HH:mm"
                    )}`
                  }}
                  tertiaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKeySeparator={","}
                  onItemSelect={onSelectDeviceHandler}
                />
              </>
            )}
            <ButtonGroup
              sticky="bottom"
              position="around"
              tw="px-4 bottom-0 bg-white"
            >
              <Button type="button" variant="cancel" onClick={onCancelHandler}>
                <span tw="flex items-center font-normal">
                  <Icon icon="chevron-left" tw="w-6 h-6 mr-2" />
                  <span tw="text-xl">Tilbage</span>
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </PopOver>
      </div>
    </PopOver>
  )
}
