export type CollectionFilter = {
  key: string
  group: string
  label: string
  value: string
}

export const filterCollection = (
  collection: any[],
  filters: CollectionFilter[]
) => {
  if (!collection) return []
  const reducedFilters = filters.reduce((acc, curr) => {
    if (acc[curr.key]) {
      return {
        ...acc,
        [curr.key]: [...acc[curr.key], curr.value],
      }
    }
    return {
      ...acc,
      [curr.key]: [curr.value],
    }
  }, {})

  const filterKeys = Object.keys(reducedFilters)

  const filteredCollection = collection.filter((item) => {
    if (filterKeys.length > 0) {
      return (
        filterKeys
          .map((key) => {
            // account for values that can be a list of things
            if (
              item[key] &&
              typeof item[key] === "object" &&
              item[key].hasOwnProperty("length")
            ) {
              return reducedFilters[key].some((value) =>
                item[key].includes(value)
              )
            } else {
              return reducedFilters[key].some((value) => item[key] === value)
            }
          })
          .filter(Boolean).length === filterKeys.length
      )
    }
    return true
  })

  return filteredCollection
}
