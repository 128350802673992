import React, { useContext, useMemo, useState } from "react"
import tw from "twin.macro"
import { AccountsContext } from "app/Account/context"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { getAccountVehicles, getCustomerType } from "services/accounts"
import { Filter } from "app/DataTable/Filter"
import { deviceFilterGroups, deviceStockStatus } from "app/Devices/helper"
import { CollectionFilter } from "utils/collection/filter"
import uniq from "lodash-es/uniq"
import { useAccount } from "app/Account/hooks"
import { SearchProvider } from "app/Search/context"
import { Button, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { deleteVehicle } from "services/vehicles"
import cogoToast from "@clevertrackdk/cogo-toast"
import { DevicesContext } from "app/Devices/context"
import { StockType } from "app/DataTable/components/StockType"
import format from "date-fns/format"
import uniqBy from "lodash-es/uniqBy"
import PopOver from "app/PopOver"
import { useDeviceConfiguration } from "app/Configuration/hooks"
import { Select } from "lib/Select"
import { ConfigurationContext } from "app/Configuration/context"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { DeviceStockStatusEnum, DeviceTypeEnum } from "app/Devices/types"
import { useFirestoreDevices } from "services/firestore/devices"
import { FeatureComponent } from "app/FeatureComponent"
import { DeviceConfiguration } from "app/Configuration/types"

enum SelectedDeviceActions {
  Delete = "delete",
  UpdateConfiguration = "updateConfiguration",
  UpdateStockStatus = "updateStockStatus",
}

const availableActions = [
  {
    label: "Konfigurer enheder",
    value: SelectedDeviceActions.UpdateConfiguration,
  },
  {
    label: "Slet enheder",
    value: SelectedDeviceActions.Delete,
  },
  {
    label: "Opdatér lagerstatus",
    value: SelectedDeviceActions.UpdateStockStatus,
  },
]

export const AccountDevices: React.FC = ({ ...props }) => {
  const {
    state: { accountVehicles, accounts, account },
    dispatch,
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { configurationList },
  } = useContext(ConfigurationContext)
  const {
    configurationListOptions,
    configurationUpdatePartOptions,
    updateDeviceConfiguration,
  } = useDeviceConfiguration()
  const {
    createFirebaseDevices,
    deleteInstallationStepsByImei,
    saveFirebaseDevice,
  } = useFirestoreDevices()
  const [
    selectedAction,
    setSelectedAction,
  ] = useState<SelectedDeviceActions | null>(null)
  const { getApiDevices } = useBackgroundDataFetch()
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [selectedDevices, setSelectedDevices] = useState<any[]>([])
  const [selectedStockStatus, setSelectedStockStatus] = useState(null)
  const [selectedConfig, setSelectedConfig] = useState<{
    label: string
    value: any
  } | null>(null)
  const [selectedConfigParts, setSelectedConfigParts] = useState<
    { label: string; value: any }[]
  >([])
  useAccount()

  useMemo(async () => {
    if (account) {
      await getCustomerType()
      const { accountVehicles } = await getAccountVehicles(account.id)
      const accountDevices = devices.filter((x) => x.accountID === account.id)
      const deletedVehicles = accountVehicles?.filter(
        (vehicle) => vehicle.deleted
      )
      const mappedVehicles = uniqBy(
        accountDevices.map((device) => {
          const identifiedVehicle = accountVehicles
            ?.filter((x) => +x.imei === +device.imei)
            .find((x) => !x.deleted)
          if (identifiedVehicle) {
            return {
              deleted: false,
              ...identifiedVehicle,
              ...device,
              id: identifiedVehicle.id,
              deviceType: DeviceTypeEnum[identifiedVehicle.deviceType],
              deviceTypeName: identifiedVehicle.deviceType,
            }
          }
          return device
        }),
        "imei"
      )
      if (accountVehicles) {
        dispatch(
          AccountsActions(AccountTypes.SetAccountVehicles, {
            accountVehicles: [...mappedVehicles, ...deletedVehicles],
          })
        )
      }
    }
    /* if (devices.length > 0 && account) {
      return devices.filter((device) => +device.accountID === account.id)
    } */
    return []
  }, [account, devices])

  const onMultiSelectHandler = (rows) => {
    setSelectedDevices(rows)
  }

  const onDeleteHandler = async () => {
    const deletePromises = selectedDevices.map((x) => deleteVehicle(x.id))
    const results = await Promise.all(deletePromises)
    if (results.find((x) => x.result !== "OK")) {
      cogoToast.error("Nogle enheder kunne ikke slettes")
    } else {
      // Here, we also need to handle what needs to happen to the device behind the vehicle.
      const resetDevices = selectedDevices.map((x) => ({
        account: null,
        accountID: null,
        comment: null,
        config: x.config ?? null,
        deviceCategory: x.deviceCategory,
        deviceType: x.deviceType,
        id: null,
        imei: x.imei,
        simicc: x.simicc ?? null,
        stockStatus: DeviceStockStatusEnum.Stock,
        unit_id: x.unit_id,
        uuid: x.uuid ?? null,
      }))
      const deleteInstallationSteps = selectedDevices.map((x) =>
        deleteInstallationStepsByImei(x.imei.toString())
      )
      await createFirebaseDevices(resetDevices)
      await Promise.all(deleteInstallationSteps)
      await getApiDevices()
      cogoToast.success("Enheder slettet")
    }
  }

  const availableDeviceFilterGroups = useMemo(() => {
    const availableDeviceTypes = uniq(
      accountVehicles.map((x) => x.deviceTypeName)
    )

    return deviceFilterGroups.map((group) => {
      if (group.dataKey === "deviceType") {
        return {
          ...group,
          dataKey: "deviceTypeName",
          options: group.options.filter((x) =>
            availableDeviceTypes.includes(x.label)
          ),
        }
      }
      return group
    })
  }, [accountVehicles, deviceFilterGroups])

  const onUpdateDeviceConfiguration = async () => {
    try {
      if (selectedConfig) {
        const cfg = configurationList.find(
          (config) => config.id === selectedConfig.value
        )
        if (cfg) {
          const devicesToUpdate = selectedDevices
            .filter((x) => !x.deleted)
            .map((device) => {
              return {
                ...device,
                id: device.unit_id,
                vehicleID: device.id,
                imei: device.imei,
              }
            })
          const result = await updateDeviceConfiguration(
            devicesToUpdate,
            cfg,
            true,
            selectedConfigParts
              .map((x) => x.value)
              .filter(Boolean) as (keyof DeviceConfiguration)[]
          )

          if (result === "OK") {
            setSelectedDevices([])
            await getApiDevices()
          }
        } else {
          cogoToast.error("Ingen konfiguration valgt.")
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke opdatere enhederne med ny konfiguration.")
      console.log(error)
    }
  }

  const onUpdateStockStatus = async () => {
    try {
      const updatePromises = selectedDevices.map((row) => {
        return saveFirebaseDevice(
          { stockStatus: selectedStockStatus },
          row.imei
        )
      })
      const result = await Promise.all(updatePromises)
      if (result && !result.map((x) => x?.result).includes("Error")) {
        cogoToast.success("Enheder opdateret med ny lagerstatus")
      } else {
        cogoToast.error(
          "Nogle enheder kunne ikke opdateres med ny lagerstatus."
        )
      }
      await getApiDevices()
    } catch (error) {
      cogoToast.error("Kunne ikke opdatere enhederne med ny lagerstatus.")
      console.log(error)
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>{account?.name}: Enheder</h2>
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={availableDeviceFilterGroups}
          filterByLabel
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
        />
        <FeatureComponent>
          <PopOver
            tw="z-100 py-4 px-8 bottom-0 left-0 flex items-center absolute bg-white shadow-none overflow-visible"
            show={selectedDevices.length > 0}
            selector="#dataTableFooter"
            fromBottom
          >
            <div tw="w-1/6 mr-8">
              <Select
                tw="bg-brand-gray-brand"
                placeholder="Vælg en handling"
                options={availableActions}
                onChange={(opt) => setSelectedAction(opt.value)}
                value={availableActions.find((x) => x.value === selectedAction)}
                menuPlacement="auto"
              />
            </div>
            {selectedAction === SelectedDeviceActions.Delete && (
              <>
                <Button
                  type="button"
                  variant="danger"
                  icon="right"
                  onClick={() => onDeleteHandler()}
                >
                  <span>
                    {selectedDevices.length === 1
                      ? `Slet ${selectedDevices.length} enhed`
                      : `Slet ${selectedDevices.length} enheder`}
                  </span>
                  <Icon icon="trash-alt" size={IconSizeEnum.SM} />
                </Button>
              </>
            )}
            {selectedAction === SelectedDeviceActions.UpdateConfiguration && (
              <>
                <div tw="w-1/6 mr-8">
                  <Select
                    tw="bg-brand-gray-brand"
                    placeholder="Vælg en konfiguration"
                    options={configurationListOptions}
                    onChange={(opt) => setSelectedConfig(opt)}
                    value={selectedConfig}
                    menuPlacement="auto"
                  />
                </div>
                <div tw="w-1/6">
                  <Select
                    tw="bg-brand-gray-brand"
                    placeholder="Vælg hvad der skal opdateres"
                    options={configurationUpdatePartOptions}
                    onChange={(opt) => setSelectedConfigParts(opt)}
                    isMulti
                    menuPlacement="auto"
                  />
                </div>
                <Button
                  type="button"
                  variant="default"
                  disabled={!selectedConfig || selectedConfigParts.length === 0}
                  icon="right"
                  size="sm"
                  tw="ml-8"
                  onClick={onUpdateDeviceConfiguration}
                >
                  <span>
                    Opdatér {selectedDevices.length} enheder med konfigurationen
                    '{selectedConfig ? selectedConfig.label : "konfiguration"}'
                  </span>
                  <Icon icon="upload" size={IconSizeEnum.SM} />
                </Button>
              </>
            )}
            {selectedAction === SelectedDeviceActions.UpdateStockStatus && (
              <>
                <div tw="w-1/5">
                  <Select
                    tw="bg-brand-gray-brand"
                    placeholder="Vælg en status"
                    options={deviceStockStatus}
                    onChange={(opt) => setSelectedStockStatus(opt.value)}
                    menuPlacement="auto"
                  />
                </div>
                <Button
                  type="button"
                  variant="default"
                  icon="right"
                  size="sm"
                  tw="ml-8"
                  onClick={onUpdateStockStatus}
                >
                  <span>
                    Opdatér {selectedDevices.length} enheder med lagerstatus:{" "}
                    {selectedStockStatus
                      ? selectedStockStatus
                      : "<Vælg status>"}
                  </span>
                  <Icon icon="key" size={IconSizeEnum.SM} />
                </Button>
              </>
            )}
          </PopOver>
        </FeatureComponent>

        <DataTable
          columns={[
            { key: "id", title: "ID" },
            { key: "unit_id", title: "Device ID" },
            { key: "name", title: "Navn" },
            { key: "note", title: "Beskrivelse" },
            {
              key: "stockStatus",
              title: "Monteringsstatus",
              component: StockType,
            },
            {
              key: "installationStartedOnAccountID",
              title: "Installeres for",
              formatData: (d) => {
                if (d === null) return null
                return (
                  <span tw="text-lg px-2 py-1 bg-brand-gray-brand inline-block overflow-hidden truncate whitespace-nowrap max-w-full">
                    {accounts.find((acc) => acc.id === d).name}
                  </span>
                )
              },
            },
            {
              key: "statusUpdated",
              title: "Status ændret",
              formatData: (d) => format(new Date(d), "dd-MM-yyyy 'kl. ' HH:mm"),
            },
            { key: "description", title: "Montering" },
            { key: "deviceTypeName", title: "Enhedstype" },
            {
              key: "config",
              title: "Konfiguration",
              formatData: (d) => d.name,
            },
            { key: "imei", title: "IMEI/ID" },
            { key: "simicc", title: "SIMICC" },
          ]}
          columnConfig="2rem 6rem 6rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
          onMultiSelect={onMultiSelectHandler}
          multiSelectIdentifierKey="unit_id"
          filters={selectedFilters}
          dataset={accountVehicles}
          searchKeys={["id", "imei", "simicc", "name", "deviceType"]}
          // onRowSelect={onSelectAccountHandler}
          tw="px-8"
          deletedProp="deleted"
          deletedStyle={tw`text-brand-red-300`}
          compactSettings={{
            titleKey: "imei",
            descriptionKey: ["deviceTypeName"],
            secondaryDescriptionKey: ["stockStatus", "simicc"],
            secondaryDescriptionKeySeparator: " - ",
          }}
        />
      </SearchProvider>
    </MainContainer>
  )
}
