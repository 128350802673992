import React, { useContext, useEffect, useMemo, useState } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledForm,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceHealthValidationModel,
  InstallationScreenEnum,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { Button, Icon, ButtonGroup, IconFlip } from "@clevertrack/shared"
import { InstallationContext } from "../context"
import { useInstallation } from "../hooks"
import { useFormData } from "hooks/useFormData"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { InstallationStepTheme } from "./Tasks"
import styled from "styled-components"
import tw from "twin.macro"

const StyledIconFlip = styled(IconFlip)`
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`

const StyledChecklist = styled.ul`
  ${tw`m-0 list-none p-0 border border-solid border-0 border-t border-brand-gray-brand`}

  li {
    ${tw`px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand items-center`}
    display: grid;
    grid-template-columns: auto auto;
  }
`

export const DeviceHealthValidation: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen, vehicleGroups },
  } = useContext(InstallationContext)
  const [ignitionWasOK, setIgnitionWasOK] = useState(false)
  const [deviceHealthValues, setDeviceHealthValues] = useState(null)
  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceHealthValidationModel
  >(
    installationSteps.find(
      (step) => step.stepID === InstallationScreenEnum.DeviceHealthValidation
    )?.data
  )
  const {
    getInstallationDeviceValuesByIMEI,
    getDeviceValuesByID,
  } = useRealtimeDeviceValues()
  const { setCurrentScreen } = useInstallation()

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceHealthValidation
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const onSnapshotHandler = (snapshot) => {
    setDeviceHealthValues(snapshot.val())
  }

  const healthChecks = useMemo(() => {
    const values = stepData?.data ?? {
      deviceIgnition: {},
      devicePower: {},
      deviceSatcountOK: {},
      deviceInternalBatteryOK: {},
    }
    if (deviceHealthValues && deviceHealthValues?.AVL_IO) {
      // Ignition, 239
      const ignition = deviceHealthValues.AVL_IO.find((x) => x.ID === 239)
      // Internal battery, 113
      const battery = deviceHealthValues.AVL_IO.find((x) => x.ID === 113)
      // Power, 66
      const power = deviceHealthValues.AVL_IO.find((x) => x.ID === 66)

      values.deviceIgnition = {
        valid: ignition.value === 1 || ignitionWasOK,
        value: `${ignition.value === 1 ? "OK" : "Ingen tænding"}`,
      }
      if (ignition.value === 1) setIgnitionWasOK(true)
      values.devicePower = {
        valid: power.value > 10000,
        value: `${(power.value / 1000).toFixed(2)}V`,
      }
      values.deviceSatcountOK = {
        valid: deviceHealthValues.satcount > 0,
        value: `${deviceHealthValues.satcount} ${
          deviceHealthValues.satcount === 1 ? `satellit` : `satellitter`
        }`,
      }
      values.deviceInternalBatteryOK = {
        valid: battery.value > 0,
        value: `${battery.value}%`,
      }

      updateModel("deviceIgnition", values.deviceIgnition)
      updateModel("devicePower", values.devicePower)
      updateModel("deviceSatcountOK", values.deviceSatcountOK)
      updateModel("deviceInternalBatteryOK", values.deviceInternalBatteryOK)
    }
    return values
  }, [deviceHealthValues])

  const unsubscribeToDevice = useMemo(() => {
    if (device) {
      if (device.id !== null) {
        return getDeviceValuesByID(device.id.toString(), onSnapshotHandler)
      } else if (device.imei) {
        return getInstallationDeviceValuesByIMEI(
          device?.imei.toString(),
          onSnapshotHandler
        )
      }
    }
    return null
  }, [device])

  const onSubmitHandler = () => {
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: InstallationStepStateEnum.Completed,
    }

    onSave(InstallationScreenEnum.DeviceHealthValidation, newData, null)
  }

  useEffect(() => {
    if (!device && unsubscribeToDevice) {
      // unsubscribe
      unsubscribeToDevice()
    }
  }, [device, unsubscribeToDevice])

  const {
    deviceIgnition,
    devicePower,
    deviceSatcountOK,
    deviceInternalBatteryOK,
  } = healthChecks

  return (
    <StyledPopoverContentContainer>
      <FlowHeader />
      <ScreenHeader tw="flex-col items-start">
        <span>Forbindelse</span>
        <span tw="text-lg mt-2 opacity-60">
          Sæt tænding på bilen eller maskinen - værdierne opdateres automatisk
        </span>
      </ScreenHeader>
      <StyledForm onSubmit={onSubmitHandler}>
        <StyledChecklist>
          <li
            css={
              deviceIgnition.valid
                ? InstallationStepTheme.Completed
                : InstallationStepTheme["Not started"]
            }
          >
            <span>Test af tænding</span>
            <span tw="flex items-center justify-end">
              <span>{deviceIgnition.value}</span>
              <span tw="w-8 h-8 ml-4">
                <StyledIconFlip
                  toggled={deviceIgnition.valid}
                  iconOff="close"
                  iconOn="check"
                />
              </span>
            </span>
          </li>
          <li
            css={
              devicePower.valid
                ? InstallationStepTheme.Completed
                : InstallationStepTheme["Not started"]
            }
          >
            <span>Enheden har strøm</span>
            <span tw="flex items-center justify-end">
              <span>{devicePower.value}</span>
              <span tw="w-8 h-8 ml-4">
                <StyledIconFlip
                  toggled={devicePower.valid}
                  iconOff="close"
                  iconOn="check"
                />
              </span>
            </span>
          </li>
          <li
            css={
              deviceSatcountOK.valid
                ? InstallationStepTheme.Completed
                : InstallationStepTheme["Not started"]
            }
          >
            <span>Enheden har GPS-forbindelse</span>
            <span tw="flex items-center justify-end">
              <span>{deviceSatcountOK.value}</span>
              <span tw="w-8 h-8 ml-4">
                <StyledIconFlip
                  toggled={deviceSatcountOK.valid}
                  iconOff="close"
                  iconOn="check"
                />
              </span>
            </span>
          </li>
          <li
            css={
              deviceInternalBatteryOK.valid
                ? InstallationStepTheme.Completed
                : InstallationStepTheme["Not started"]
            }
          >
            <span>Internt batteri</span>
            <span tw="flex items-center justify-end">
              <span>{deviceInternalBatteryOK.value}</span>
              <span tw="w-8 h-8 ml-4">
                <StyledIconFlip
                  toggled={deviceInternalBatteryOK.valid}
                  iconOff="close"
                  iconOn="check"
                />
              </span>
            </span>
          </li>
        </StyledChecklist>
        <ButtonGroup sticky="bottom" tw="bg-white px-4 z-100">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">Tilbage</span>
            </span>
          </Button>
          <Button type="submit" variant="primary">
            Gem
          </Button>
        </ButtonGroup>
      </StyledForm>
    </StyledPopoverContentContainer>
  )
}
