import React, { useReducer, createContext } from "react"
import { InstallationActionMap, InstallationTypes } from "./actions"
import { Account } from "app/Account/types"
import { ApiVehicle, ApiVehicleGroup, Device } from "app/Devices/types"
import { InstallationScreenEnum, InstallationStep } from "./types"
import { updateCollectionItemByIndex } from "utils/array"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialInstallationStateType = {
  account: Account | null
  accountDevices: Device[] | []
  device: Device | null
  vehicleGroups: ApiVehicleGroup[]
  currentDeviceValues: any | null
  currentScreen: InstallationScreenEnum | null
  installationSteps: InstallationStep[] | null
  vehicles: ApiVehicle[] | []
  fotaDevice: any | null
}

const initialState: InitialInstallationStateType = {
  account: null,
  accountDevices: [],
  device: null,
  vehicleGroups: [],
  currentDeviceValues: null,
  installationSteps: null,
  currentScreen: null,
  vehicles: [],
  fotaDevice: null,
}

const InstallationContext = createContext<{
  state: InitialInstallationStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const installationReducer = (
  state: InitialInstallationStateType,
  action: InstallationActionMap
) => {
  switch (action.type) {
    case InstallationTypes.SetAccount:
      return {
        ...state,
        account: action.payload.account,
      }
    case InstallationTypes.SetAccountDevices:
      return {
        ...state,
        accountDevices: action.payload.accountDevices,
      }
    case InstallationTypes.SetDevice:
      return {
        ...state,
        device: action.payload.device,
      }
    case InstallationTypes.SetCurrentDeviceValues:
      return {
        ...state,
        currentDeviceValues: action.payload.currentDeviceValues,
      }
    case InstallationTypes.SetInstallationSteps:
      return {
        ...state,
        installationSteps: action.payload.steps,
      }
    case InstallationTypes.SetInstallationStep:
      if (!state.installationSteps) return state
      const filteredSteps = state.installationSteps.filter(
        (step) => step.stepID !== action.payload.step.stepID
      )
      return {
        ...state,
        installationSteps: [...filteredSteps, action.payload.step],
      }
    case InstallationTypes.SetAccountVehicleGroups:
      return {
        ...state,
        vehicleGroups: action.payload.groups,
      }
    case InstallationTypes.SetVehicles:
      return {
        ...state,
        vehicles: action.payload.accountVehicles,
      }
    case InstallationTypes.SetFotaDeviceData:
      return {
        ...state,
        fotaDevice: action.payload.fotaDevice,
      }
    case InstallationTypes.SetScreen:
      return {
        ...state,
        currentScreen: action.payload,
      }
    case InstallationTypes.UpdateDeviceByID:
      if (state.accountDevices) {
        const newDevices = updateCollectionItemByIndex<Device>(
          state.accountDevices,
          action.payload.device.imei,
          action.payload.device,
          "imei"
        )
        return {
          ...state,
          accountDevices: newDevices,
        }
      }
      return state
    case InstallationTypes.ClearDevice:
      return {
        ...state,
        device: null,
        installationSteps: null,
        fotaDevice: null,
      }
    case InstallationTypes.ClearAccount:
      return {
        ...state,
        account: null,
      }
    default:
      return state
  }
}

const InstallationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(installationReducer, initialState)

  return (
    <InstallationContext.Provider value={{ state, dispatch }}>
      {children}
    </InstallationContext.Provider>
  )
}

export { InstallationContext, InstallationProvider }
